import React from 'react';

import Layout from '../components/layout';

const Error500Page = () => (
  <Layout>
    <h1>Whoops</h1>
  </Layout>
);

export default Error500Page;
